
.script-main {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 340px);
    .coverImg{
        width:99px;
        height: 57px;
        display: inline-block;
        img{
            width:99px;
            height: 57px;
        }
    }
}
