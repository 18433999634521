
.script-creation-main {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    .script-wrapper {
        display: flex;
        height: 1%;
        flex: 1;
        flex-direction: column;
        background: #fff;
        border-radius: 10px;
        padding: 30px 40px;
        .script-creation-tabs {
            .script-tabs {
                display: inline-block;
                margin-right: 20px;
                position: relative;
                cursor: pointer;
                transition: all .3s;
                background-color: #F1F0FE;
                color: #574DED;
                border-radius: 4px;
                padding: 4px 16px;

                &:last-child {
                    margin-right: 0;
                }

                &.current {
                    background-color: #574DED;
                    color: #fff;
                }

                &:hover {
                    background-color: #1E33E4;
                    color: #fff;
                }
            }
        }

        .script-item {
            display: none;
            height: 1%;
            flex: 1;
            justify-content: flex-start;
            .script-item-left {
                width: 35%;
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                .script-content {
                    border-bottom: 1px solid #EAEAEA;
                    .script-model {
                        text-align: center;
                        padding:20px 0;
                    }
                    .hot-script {
                        text-align: left;
                        padding:20px;
                    }
                }
                .script-list {
                    height: 1%;
                    flex: 1;
                    margin: 10px 0;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }

                    .item {
                        padding: 16px;
                        border-bottom: 1px solid #EAEAEA;

                        .script {
                            margin-bottom: 10px;
                            color: #333;
                        }
                        .script-btn {
                            text-align: right;
                            .script-btn-blue {
                                border-radius: 4px;
                                background: #574DED;
                                color: #fff;
                                &:hover {
                                    background: #1E33E3;
                                }
                            }
                            ::v-deep.el-button--danger.is-plain{
                                color: #574DED;
                                background: #F1F0FE;
                                border-color: #F1F0FE;
                            }
                            ::v-deep.el-button--danger.is-plain:focus, ::v-deep.el-button--danger.is-plain:hover {
                                background: #574DED;
                                border-color: #574DED;
                                color: #FFF;
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        .item-top {
                            display: flex;

                            .script-img {
                                width: 93px;
                                height: 58px;
                                display: inline-block;
                                margin-right: 10px;
                                img{
                                    width: 93px;
                                    height: 58px;
                                }
                            }
                        }
                    }
                }
            }

            .script-item-right {
                display: flex;
                flex-direction: column;
                padding-left: 20px;
                border-left: 1px solid #eee;

                .script-list01 {
                    flex: 1;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }

            }

            &.current {
                display: flex;
            }

            .script-item {
                flex: 1;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }

            .script-content-list {
                width: 100%;
                padding:30px 0;
                box-sizing: border-box;
                .script-content-tab {
                    ::v-deep .el-tabs__item {
                        height: 45px;
                        line-height: 45px;
                        font-size: 16px;
                        font-weight: 400;
                        border: none;
                        color: #16113F;
                    }

                    ::v-deep .el-tabs__active-bar {
                        background: #9ACC5B;
                    }

                    ::v-deep .el-tabs__active-bar:before {
                        position: absolute;
                        top: -13px;
                        left: 40%;
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: dashed dashed solid dashed;
                        border-width: 7px;
                        border-color: transparent transparent #9ACC5B transparent;
                    }
                }
            }
        }
    }
    .content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
        .item{
            .btn-red-script{
                display: inline-block;
                color: #F90B32 ;
                font-size: 14px;
            }
            .btn-red-script:hover,
            .btn-red-script:focus,
            .btn-red-script.current {
                color: #dc0012;
            }
        }
    }
    .script-search {
        display: flex;
        .el-input {
            width: 1%;
            flex: 1;
            margin-left: 10px;
        }
        ::v-deep .el-input__inner {
            border: 1px solid #594FEE;
            border-radius: 15px;
        }
    }
    ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
}
