
.video-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    .play-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        z-index: -1;
    }
}
.play-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    position: relative;
    .play-box {
        background-color: #000;
        position: relative;
        .close-icon {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: -25px;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            i {
                color: #FFF;
                font-size: 20px;
            }
        }
    }
}
.file-upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .file-select {
        flex: 1;
        .file-select-area {
            display: flex;
            flex: 1;
            position: relative;
            input#video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

    }
}
.wrapper {
    display: flex;
    .script-content {
        padding-right: 20px;
        ::v-deep .el-form-item {
            display: flex;
            flex-direction: column;
        }
        ::v-deep .el-form-item__content {
            margin-left: 0 !important;
        }
        ::v-deep .el-upload--picture-card {
            width: 392px;
            height: 226px;
            line-height: 226px;
        }
        .dialog-footer {
            display: flex;
            justify-content: center;
            padding: 20px 0;
        }
        .script-content-table {
            display: flex;
            flex-direction: column;
            .script-content-btn {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
            }

            ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
                border-right: 2px solid #fff;
            }
            ::v-deep .el-table--medium td{
                background:#F0F3FF;
                border-bottom: 2px solid #fff
            }
            ::v-deep.el-table__body-wrapper{
                height: auto!important;
            }
        }
    }

    .btn-blue-admin {
        color: #FFF;
        background-color: #574DED;
        border-color: #574DED;
    }

    .btn-blue-admin:hover,
    .btn-blue-admin:focus,
    .btn-blue-admin.current {
        background: #1E33E4;
        border-color: #1E33E4;
        color: #FFF;
    }

    ::v-deep .el-textarea__inner {
        height: 80px;
        resize: none;
    }

    ::v-deep .el-input__inner::-webkit-outer-spin-button,
    ::v-deep .el-input__inner::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }
    ::v-deep .el-input__inner[type="number"] {
        -moz-appearance: textfield;
    }
    .imgBox {
        /*width: 392px;*/
        /*height: 226px;*/
        .imgUplod {
            display: flex;
            flex-direction: column;
            position: absolute;
            background: rgba(0,0,0,.3);
            width: 389px;
            height: 224px;
            /* line-height: 224px; */
            justify-content: center;

            ::v-deep .el-button--text {
                color: #fff;
            }
            ::v-deep.el-icon-upload2{
                color: #fff;
            }
        }
        .bgColor {
            background: #ECEBFF;
            width: 389px;
            height: 224px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            ::v-deep.el-button--text{
                color: #333;
            }
            ::v-deep.el-upload--picture-card i{
                color: #333;
            }
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
    }

    .cover-prompt {
        color: #f56c6c;
    }
}
::v-deep .el-table tbody tr:hover > td {
    background-color: transparent;
}
